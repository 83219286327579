h1 {
  font-size: 32px;
  font-weight: 700;
}
h2 {
  font-weight: 300;
}

.ant-modal-mask {
  background-color: rgb(255 255 255 / 0%);
  backdrop-filter: blur(5px);
}

.green-btn {
  background: #27ae60;
  border-color: #27ae60;
}

.green-btn:hover {
  background: #27ae60;
  border-color: #27ae60;
}

.green-btn:focus {
  background: #27ae60;
  border-color: #27ae60;
}

.green-btn:active {
  background: #27ae60;
  border-color: #27ae60;
}

.ant-btn:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.09);
}
.ant-tabs-top.dataview-nav  > .ant-tabs-nav {
  margin: 0 24px;
}
i.anticon-arrow-left {
  color: rgba(0, 0, 0, 0.65);
}
i.anticon-arrow-left:hover {
  color: #1890ff;
}
.back-icon {
  margin: 12px;
  position: absolute;
  z-index: 9999;
  top: 0;
  font-size: 12px;
  color: black;
  background: #fff;
  border-radius: 12px;
  padding: 12px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.back-icon:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.09);
}

.logo img {
  margin: 16px auto;
  width: 100%;
  height: 60px;
  padding: 10px;
}

.product {
  max-width: 100%;
  margin: auto;
}
.ant-form-item {
  margin-bottom: 6px;
}
.ant-list-vertical .ant-list-item-meta-title {
  font-size: 24px;
  font-weight: 700;
}

.ant-list-item h2 {
  margin-right: 12px;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 10px !important;
}
.addNew {
  text-align: right;
}
.download {
  margin-right: 6px;
}
/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
.ck,
.ck-content,
.ck-editor__editable,
.ck-rounded-corners,
.ck-editor__editable_inline,
.ck-blurred {
  height: 300px;
}

.ant-statistic-title {
  margin-bottom: 4px;
  color: rgb(251 251 251 / 85%);
  font-size: 16px;
}
.antd-pro-charts-chart-card-meta, .antd-pro-charts-chart-card-total {
  text-align: left;
}

.ant-card {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 16px;
  border-radius: 12px;
}
.ant-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.09);
}
.ant-card-head-title {
  font-size: 24px;
  font-weight: bold;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.profile {
  display: inline-flex;
  align-items: center;
}
.profile img {
  margin-right: 12px;
}
.profile h3 {
  margin-right: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
table {
  width: 100%;
}
.ant-table {
  font-size: 12px;
}
.ant-collapse-extra {
  position: absolute;
  right: 0;
  top: 0;
  padding: 7px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.85);
}
.settings .ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 100%;
  height: auto;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #73d13d;
  border-width: 3px;
}

.copy-icon {
  font-size: 24px;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.45);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.copy-icon:hover {
  color: #40a9ff;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e0e0e0;
}
.order-date {
  margin-right: 12px;
  font-weight: 700;
}
.order-status {
  font-size: 12px;
  margin-right: 4px;
}

.ant-list-footer {
  background: #000c17;
  color: white;
  font-size: 24px;
  font-weight: 700;
  padding: 12px;
}

.products-list .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2ecc71;
  border-color: #2ecc71;
}

/* SORT LIST  */

.list-sort-demo-wrapper {
  position: relative;
  background: #f0f2f5;
  overflow: hidden;
  border-radius: 12px;
}

.list-sort-demo {
  margin: 40px auto;
  width: 60%;
  cursor: url("http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png") 10
      10,
    pointer !important;
  position: relative;
}
.list-sort-demo > div {
  overflow: hidden;
}

.list-sort-demo-list {
  background: #fff;
  border-radius: 6px;
  margin: 5px auto;
  padding: 10px;
  height: 70px;
  transition: box-shadow 0.5s, transform 0.5s;
}

.list-sort-demo-list.list-drag-selected {
  box-shadow: 0 8px 20px #e6e6e6;
  transform: scale(1.1) !important;
}

.list-sort-demo-icon {
  width: 20%;
  display: inline-block;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  vertical-align: top;
}
.list-sort-demo-text {
  width: 80%;
  display: inline-block;
}

.list-sort-demo-text h1 {
  font-size: 18px;
}

.list-sort-demo-text p {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 320px) {
  .list-sort-demo-text h1 {
    font-size: 14px;
    line-height: 28px;
  }
}

.ant-layout-header {
  height: auto;
  min-height: 64px;
  line-height: 0;
}
/* END OF SORT LIST */
